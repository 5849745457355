import React, { ReactElement } from "react";

import colors from "@styles/variables/cardprocessingOffersVariables.module.scss";
import FeatureAndFunctionalityIcon from "../icons/featureAndFunctionality";
import CustomerSupportIcon from "../icons/customerSupportIcon";
import EasyOfUseIcon from "../icons/easyOfUse";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Ease of Use",
        description:
            "We selected companies that offer credit card processors that are user-friendly and simple to set up. This encompasses the smooth importation of data and invoices and an easy-to-use reporting system.",
        icon: <EasyOfUseIcon />,
    },
    {
        title: "Features & Benefits",
        description:
            "In our comparisons, we considered essential features and tools, such as software integrations, data exports, invoicing, and reporting dashboards.",
        icon: <FeatureAndFunctionalityIcon />,
    },
    {
        title: "Customer Support",
        description:
            "We examined companies that provide a range of customer support options, such as phone support, live chat, blogs, and FAQs.",
        icon: <CustomerSupportIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Why Choose CardProcessingOffers.com?"
            colors={{
                bgColor: colors.whyUsBackgroundColor,
                mainTitleColor: "white",
                titleColor: "white",
                descriptionColor: "white",
            }}
            classes={{
                mainSectionClasses: "max-w-7xl mx-auto block px-4vw lg:px-4",
                mainTitleClasses: "text-center text-xl  lg:text-3xl py-14",
                innerSectionClasses:
                    "lg:flex pb-4 lg:pb-0 flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
